
.desktop {
  display: none;
}

@media (min-width: 768px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
}